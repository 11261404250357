import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { OrganizerWebsite } from '@ark/components/organizer-website/models/website-models';
import { WeekDay } from '@index/enums';
import { AgeRangeEnum } from '@index/enums/age-range';
import { GthPlaceModel } from '@sentinels/models/place';
import firebase from 'firebase/compat';

import { EventItemTypes } from '../enums/event-item-type';
import { SkillLevel } from '../enums/skill-level';
import { SubscriptionType } from '../enums/subscription-type';
import { Location } from './location';
import { TeamWebsite } from './team';
import { User } from './user';

/**
 * Represents GTH/MEH Event
 */
export interface EventItem {
  id?: string;
  creator: string;
  title: string;
  gameType: string;
  isPrivate: boolean;
  duration: EventDuration;
  teamName: string;
  hostingTeam: string | UnregisteredTeamInfo;
  cost?: number;
  dateStart: Date;
  groups?: string[];
  equipmentNeeded: string[];
  makePublicAfter: number | null;
  minimumNeeded?: number | null;
  hoursToCancelBefore: number | null;
  creatorApprovalNeeded: boolean;
  allowParticipantGuests: boolean;
  eventSlotGroup: EventSlotGroup[];
  type?: EventItemTypes;
  description?: string;
  eventTeamsOnly?: null | boolean;
  joiners: EventJoiner[];
  banner?: string;
  externalLink?: string;
  cancelled?: boolean;
  updated: firebase.firestore.Timestamp;
  rated: boolean;
  skillLevels?: SkillLevel[];
  discoverable?: boolean;
  theme?: EventTheme;
  backgroundColor?: string;
  sendFeedbackEmailAfter?: boolean;
  ticketLevels?: EventTicketLevel[];
  platform?: string;
  priceId?: string;
  survey?: EventSurvey;
  allowMaybeRsvp?: boolean;
  requireGuestInformation?: boolean;
  allowUnregistered?: boolean;
  favoritedList: string[];
  isBasic?: boolean;
  isDuprEvent?: boolean;

  locationType?: LocationType;
  location?: Location;
  online?: boolean;
  placeId?: string;
  place?: GthPlaceModel;

  recurring: EventRecurring;
  sessionId?: string;
}

export interface EventDuration {
  hours: number;
  minutes: number;
}

export interface EventGroupItem {
  name: string;
}

export interface EventSlotGroup {
  date: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  slots: EventSlot[];
}

export interface EventSlot {
  updatedAt: firebase.firestore.Timestamp;
  number: number; // Use this to track, name is mutable
  name: string;
  capacity: number;
  groupsList?: string[];
}

export interface UnregisteredTeamInfo {
  name: string;
  photoURL: string;
  id: null;
  gameType: string;
  discoverable: boolean;
  subscription: SubscriptionType;
  ageRange: AgeRangeEnum | string;
  location?: Location;
  created?: firebase.firestore.Timestamp;
  updated?: firebase.firestore.Timestamp;
  website: TeamWebsite;
  organizerWebsite?: OrganizerWebsite;
  displayPublicWebsite: boolean;
  playerCount?: number;
  online?: boolean;
  skillLevel?: SkillLevel;
}

export interface EventJoiner {
  player: string;
  createdAt: any;
  status?: EventJoinerStatus;
  approvedBy?: string[];
  guests?: EventItemGuest[]; // Todo(Ajones): delete this!
  rsvpStatus: EventRsvpStatus;
  attendanceStatus?: EventJoinerStatus;
  isGuestUser?: boolean;
  isUnregisteredUser?: boolean;
  slotNumber?: number;
  guestOfParticipant?: boolean;
  surveyAnswers?: any[];
  groupName?: string;
  eventTeamsOnly?: string;
  waitListDateTime?: Date;
  recurringDate?: Date;
}

export enum EventRsvpStatus {
  PLAYING = 'Playing',
  ATTEMPTING = 'Attempting',
  SPECTATING = 'Spectating',
  MAYBE = 'Maybe',
  NOT_PLAYING = 'Not Playing',
  WAITLISTED = 'Waitlisted',
}

export enum EventJoinerStatus {
  Approved = 'Approved',
  Waitlisted = 'Waitlisted',
  PendingCreator = 'Pending Creator',
  PendingApprovers = 'Pending Approvers',
  Dropped = 'Dropped',
  Denied = 'Denied',
  PendingJoiner = 'Pending Joiner',
  /** After event statuses */
  Attended = 'Attended',
  NoShowed = 'No Showed',
  Removed = 'Removed',
  NotCommited = 'Not Commited',
}

export enum UpdateType {
  ADD_JOINER = 'addJoiner',
  DELETE_JOINER = 'deleteJoiner',
  UPDATE_EVENT = 'updateEvent',
}

export interface UpdateEventItemRequest {
  creator: string;
  id: string;
  title?: string;
  dateStart?: Date;
  duration?: { hours: number; minutes: number };
  minimumNeeded: number;
  hoursToCancelBefore: number;
  hostingTeam: unknown;
  teamColor?: string;
  equipmentNeeded?: string[];
  makePublicAfter?: number;
  creatorApprovalNeeded?: boolean;
  allowParticipantGuests?: boolean;
  joiner: User;
  type: UpdateType;
  days?: string[];
  banner?: string;
  cancelled?: boolean;

  locationType: LocationType;
  location?: Location;
  placeId?: string;
}

export interface EventItemDeleteRequest {
  eventId: string;
  userId?: string;
}

export interface EventItemDuration {
  hours: number;
  minutes: number;
}

export interface EventJoinerUpdateRequest {
  status: EventJoinerStatus;
  player: string;
  event: string;
  rsvpStatus?: EventRsvpStatus;
  guests?: EventItemGuest[];
}

export interface GthEventItemListFilter {
  eventItemId?: string;
  lat?: number;
  lng?: number;
  userId?: string;
  teamId?: string;
}

export interface GthEventJoiner {
  player: string;
  status: EventJoinerStatus;
  assignedGenderSpot?: string;
  approvedBy: string[];
  createdAt: firebase.firestore.Timestamp;
  user?: unknown;
  slotName?: string;
  guestOfParticipant?: boolean;
  eventTeamsOnly: string;
}

// make sure the select themselves first

export interface EventItemGuest {
  type: string;
  slotName: string;
  number: number;
}

export enum EventTheme {
  Easter = 'Easter',
  Birthday = 'Birthday',
  Valentines = 'Valentines',
  Sports = 'Sports',
}

export interface EventTicketLevel {
  name: string;
  cost: number;
  priceId: string;
  slotNumber: number | null;
}

export interface EventItemFormGroup {
  general: FormGroup<{
    title: FormControl<string>;
    gameType: FormControl;
    dateStart: FormControl;
    duration: FormControl;
    privacy: FormControl;
    description: FormControl;
    banner: FormControl;
    theme: FormControl<EventTheme | null | undefined>;
    backgroundColor: FormControl<string | null>;
    days: FormControl;

    locationType: FormControl<LocationType>;
    location: FormControl<Location>;
    placeId: FormControl<string>;
    place: FormControl<GthPlaceModel>;
  }>;
  teams: FormGroup<{
    hostTeamEvent: FormControl<boolean>;
    hostingTeam: FormControl<string | UnregisteredTeamInfo>;
    teamName: FormControl<string>;
    eventTeamsOnly: FormControl<null | boolean>;
    eventType: FormControl;
    sessionId: FormControl;
  }>;
  participants: FormGroup<{
    groups: FormArray<FormControl<string>>;
    eventSlotGroup: FormArray<FormGroup<EventSlotGroupFormGroup>>;
    isBasic: FormControl<boolean>;
    basicParticipantAmount: FormControl<number>;
    allowUnregistered: FormControl<boolean>;
    requireGuestInformation: FormControl<boolean>;
    allowMaybeRsvp: FormControl<boolean>;
    allowParticipantGuests: FormControl;
    hasMinimumNeeded: FormControl<boolean>;
    minimumNeeded: FormControl<number>;
    hoursToCancelBefore: FormControl<number>;
    skillLevels: FormControl;
    creatorApprovalNeeded: FormControl;
    isDuprEvent: FormControl<boolean>;
  }>;
  details: FormGroup<{
    cost: FormControl<number>;
    ticketLevels: FormArray<FormGroup<EventTicketLevelFormGroup>>;
    equipmentNeeded: FormControl;
    equipmentNeededItem: FormControl;
    sendFeedbackEmailAfter: FormControl<boolean>;
    makePublicAfter: FormControl;
    priceId: FormControl<string | null>;
    id: FormControl<string>;
    survey: FormGroup<EventSurveyFormGroup>;
    recurring: FormGroup<EventRecurringFormGroup>;
  }>;
}

export interface EventTicketLevelFormGroup {
  name: FormControl<string>;
  cost: FormControl<number>;
  priceId: FormControl<string>;
  slotNumber: FormControl<number>;
}

export interface EventGroupFormGroup {
  name: FormControl<string>;
}

export interface EventSlotGroupFormGroup {
  date: FormControl<firebase.firestore.Timestamp | null>;
  updatedAt: FormControl<firebase.firestore.Timestamp>;
  slots: FormArray<FormGroup<EventSlotFormGroup>>;
}

export interface EventSlotFormGroup {
  updatedAt: FormControl<firebase.firestore.Timestamp>;
  number: FormControl<number>;
  name: FormControl<string>;
  capacity: FormControl<number>;
  groupsList?: FormArray<FormControl<string>>;
  applyToDate: FormControl<firebase.firestore.Timestamp>;
}

export enum RecurringType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export interface RecurringTime {
  dayOfWeek: WeekDay;
  time: firebase.firestore.Timestamp;
}
export interface RecurringTimeFormGroup {
  dayOfWeek: FormControl<WeekDay>;
  time: FormControl<Date>;
}

export interface EventRecurringFormGroup {
  recurring: FormControl<boolean>;
  recurringType: FormControl<RecurringType>;
  recurringTimes: FormArray<FormGroup<RecurringTimeFormGroup>>;
  recurringEnd: FormControl<Date | null>;
}

export interface RecurringTime {
  dayOfWeek: WeekDay;
  time: firebase.firestore.Timestamp;
}
export interface RecurringTimeFormGroup {
  dayOfWeek: FormControl<WeekDay>;
  time: FormControl<Date>;
}

export interface EventRecurringFormGroup {
  recurring: FormControl<boolean>;
  recurringType: FormControl<RecurringType>;
  recurringTimes: FormArray<FormGroup<RecurringTimeFormGroup>>;
  recurringEnd: FormControl<Date | null>;
}

export interface EventSurveyFormGroup {
  description: FormControl<string>;
  questions: FormArray<FormGroup<SurveyQuestionFormGroup>>;
  required: FormControl<boolean>;
}

export interface SurveySelectionFormGroup {
  value: FormControl<string | number>;
}

export interface SurveyQuestionFormGroup {
  text: FormControl<string>;
  required: FormControl<boolean>;
  answerType: FormControl<AnswerType>;
  page: FormControl<number>;
  selections: FormArray<FormGroup<SurveySelectionFormGroup>>;
}

export interface EventQuestion {
  text: string;
  required: boolean;
  answerType: AnswerType;
  page: number;
  selections?: EventSelection[];
}

export enum AnswerType {
  Short_Text = 'text',
  Checkbox = 'checkbox',
  Selection = 'selection',
  Number = 'number',
}

export interface EventSurvey {
  description: string;
  questions: EventQuestion[];
  required: boolean;
}

export interface EventSelection {
  value: string | number;
}

export enum LocationType {
  Online = 'online',
  Address = 'address',
  Place = 'place',
}

export interface EventRecurring {
  recurring: boolean;
  recurringType: RecurringType;
  recurringTimes: RecurringTime[];
  recurringEnd: Date | null;
}
